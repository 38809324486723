import { Injectable } from '@angular/core'

import { HttprequestService } from '@services/httprequest/httprequest.service'

import { User } from '@models/user.model'

@Injectable({
  providedIn: 'root'
})
export class AccountService {
    public user: User

    constructor(
        private _http: HttprequestService
    ) {}

    /**
     * Update user password
     * @param password : User Password
     * @returns User logged in
     */
     public UpdatePassword(login: string, password: string): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                this._http.Put("users/udpatepassword", {Login: login, Password: password})
                    .then(
                        resp => {
                            resolve(resp)
                        }
                    )
                    .catch(
                        error => {
                            reject(
                                {
                                    title: "error.updatePassword.title",
                                    message: "error.updatePassword.message"
                                }
                            )
                        }
                    )
            }
        )
    }

    /**
     * Reset user password
     * @param login : User Login
     * @returns Boolean
     */
    public ResetPassword(login: string): Promise<boolean> {
        return new Promise<boolean>(
            (resolve, reject) => {
                this._http.Post("users/resetpassword", {Login: login, Password: "resetpassword"})
                    .subscribe(
                        resp => {
                            resolve(resp)
                        },
                        error => {
                            if(error.status === 404) {
                                reject(
                                    {
                                        title: "error.resetpassword.notfound.title",
                                        message: "error.resetpassword.notfound.message"
                                    }
                                )
                            }
                            else if (error.error.message == "User is inactive") {
                                reject(
                                    {
                                        title: "error.resetpassword.inactiveuser.title",
                                        message: "error.resetpassword.inactiveuser.message"
                                    }
                                )
                            }
                            reject(
                                {
                                    title: "error.api.title",
                                    message: "error.api.message"
                                }
                            )
                        }
                    )
            }
        )
    }
}
