import { Injectable } from '@angular/core';
import { Consultation } from '@models/consultation.model';
import { HttprequestService } from '@services/httprequest/httprequest.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultationService {

    constructor(
        private _http: HttprequestService,
    ) { }


    /**
     * Update a document
     * @param requestId 
     * @param document 
     * @returns 
     */
    public GetById(id: number, supplierId: number): Promise<Consultation> {
        return new Promise<Consultation>(
            (resolve, reject) => {
                this._http.Get("consultation/GetById/" + id + "/" + supplierId)
                    .then(
                        resp => {
                            resolve(new Consultation(resp))
                        }
                    )
                    .catch(
                        error => {
                            reject(
                                {
                                    title: "error.api.title",
                                    message: "error.api.message"
                                }
                            )
                        }
                    )
            }
        )
    }

    /**
     * Update a document
     * @param requestId 
     * @param document 
     * @returns 
     */
    public GetBySupplierId(id: number): Promise<Array<Consultation>> {
        return new Promise<Array<Consultation>>(
            (resolve, reject) => {
                this._http.Get("consultation/GetBySupplierId/" + id)
                    .then(
                        resp => {
                            resolve(resp.map(x => new Consultation(x)))
                        }
                    )
                    .catch(
                        error => {
                            reject(
                                {
                                    title: "error.api.title",
                                    message: "error.api.message"
                                }
                            )
                        }
                    )
            }
        )
    }
}
