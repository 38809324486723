import { Document, DocumentType } from './document.model';

export interface ISupplier {
  id: number;
  name: string;
  address: string;
  phone: string;
  noConformityRate: number;
  avgDelivTime: number;
  conformityScore: number;
  otdScore: number;
  globalScore: number;
  medal: number;
}

export class Supplier {
  public id: number;
  public name: string;
  public address: string;
  public phone: string;
  public noConformityRate: number;
  public avgDelivTime: number;
  public conformityScore: number;
  public otdScore: number;
  public globalScore: number;
  public medal: number;

  constructor(o: ISupplier) {
    Object.assign(this, o);
  }
}

export class SupplierDocumentType {
  public id: number;
  public supplier: Supplier;
  public documentType: DocumentType;
  public document: Document;

  constructor(o: SupplierDocumentType) {
    Object.assign(this, o);
  }
}
