import { Document } from "./document.model"
import { Site } from "./site.model"

export class Consultation {
    public id: number
    public site: Site  
    public date: Date   
    public name: string  
    public description: string  
    public deadline: Date   
    public isWon: boolean  
    public hasAnswer: boolean  
    public documents?: Array<Document> 

    constructor(o: Consultation) {
        this.id = o ? o.id : null
        this.site = o ? new Site(o.site) : null
        this.date = o ? o.date : null
        this.name = o ? o.name : null
        this.description = o ? o.description : null
        this.deadline = o ? o.deadline : null
        this.isWon = (o && o.isWon != null) ? o.isWon : null
        this.hasAnswer = (o && o.hasAnswer) ? o.hasAnswer : false
        this.documents = (o && o.documents) ? o.documents.map(x => new Document(x)) : null
    }
}


export class ConsultationAnswer {
    public id: number
    public supplierId: number  
    public consultationId: number  
    public description: string  
    public date: string   
    public documents?: Array<Document> 

    constructor(o?: ConsultationAnswer) {
        this.id = o ? o.id : null
        this.supplierId = o ? o.supplierId : 0
        this.consultationId = o ? o.consultationId : 0
        this.description = o ? o.description : null
        this.date = (o && o.date) ? o.date : null
        this.documents = (o && o.documents) ? o.documents.map(x => new Document(x)) : null
    }
}


export class ConsultationAnswerUpdate {
    public id: number
    public supplierId: number  
    public consultationId: number  
    public description: string  
    public date: string  
    public document: File 

    constructor(o?: ConsultationAnswerUpdate) {
        this.id = o ? o.id : 0
        this.supplierId = o ? o.supplierId : 0
        this.consultationId = o ? o.consultationId : 0
        this.description = o ? o.description : null
        this.date = (o && o.date) ? o.date : null
        this.document = null
    }
}