export interface ISite {
    id: number
    name: string  
    logoUrl: string           
}

export class Site {
    public id: number
    public name: string  
    public logoUrl: string   

    constructor(o: ISite) {
        this.id = o ? o.id : null
        this.name = o ? o.name : null
        this.logoUrl = o ? o.logoUrl : null
    }
}